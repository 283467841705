<template lang="">
  <div
    class="menu"
    :style="{
      backgroundImage: 'url(' + background + ')',
    }"
    v-bind="$attrs"
    v-for="(d, i) in menus"
    :key="i"
    :class="i == displayId ? 'active' : 'inactive'"
  >
    <div
      class="item-box"
      :class="d.columns == 2 ? 'two-columns' : 'three-columns'"
    >
      <div class="item top">
        <h1 class="page-title">{{ d.page_title }}</h1>
        <h3 class="page-subtitle">{{ d.page_subtitle }}</h3>
        <div class="page-description">{{ d.page_description }}</div>
      </div>
      <div class="item col" v-for="n in d.columns" :key="n">
        <table>
          <tbody>
            <tr v-for="(item, j) in menuitems" :key="j">
              <td
                v-if="item.column == n"
                class="menu-name"
                :class="[
                  item.item_type == 'description' ? 'sub' : '',
                  item.item_type == 'category' ? 'menu-category' : '',
                  item.item_type == 'unit' ? 'menu-unit' : '',
                ]"
                :colspan="
                  item.item_type == 'category' ||
                  item.item_type == 'unit' ||
                  item.item_type == 'separator'
                    ? 2
                    : 1
                "
              >
                <span v-if="item.item_type !== 'separator'">{{
                  item.item_name
                }}</span>
                <hr v-if="item.item_type == 'separator'" />
              </td>
              <td
                v-if="
                  item.column == n &&
                  item.item_type !== 'category' &&
                  item.item_type !== 'separator' &&
                  item.item_type !== 'unit'
                "
                :class="[item.item_price ? 'menu-price' : '']"
              >
                {{ item.item_price }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="item bottom">&nbsp;</div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    menus: {
      type: Array,
      default: null,
    },
    interval: {
      type: Number,
      default: 10,
    },
    background: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      displayId: 0,
      menuId: 1,
      count: 1,
      slideInterval: 60,
      timer: null,
      paused: 0,
      menuitems: null,
    };
  },
  created() {},
  mounted() {
    this.slideInterval = this.interval;
    this.setIntervals(this.interval);
    this.menuId = this.menus[this.displayId].id;
    axios
      .get(
        "http://api.eatfloweringtree.com/api/menu-item/?MenuItemSearch[menu_id]=" +
          this.menuId
      )
      .then((response) => (this.menuitems = response.data));
  },
  methods: {
    setIntervals(second) {
      this.paused = false;
      this.timer = window.setInterval(() => {
        this.rotate();
      }, 1000 * second);
    },
    rotate() {
      let count = this.menus === null ? 1 : this.menus.length;
      this.displayId = (this.displayId + 1) % count;
      this.menuId = this.menus[this.displayId].id;
      axios
        .get(
          "http://api.eatfloweringtree.com/api/menu-item/?MenuItemSearch[menu_id]=" +
            this.menuId
        )
        .then((response) => (this.menuitems = response.data));
    },
    rotatePause() {
      this.paused = !this.paused;
      if (this.paused) {
        clearInterval(this.timer);
      } else {
        this.setIntervals(this.slideInterval);
      }
    },
  },
};
</script>
<style>
.menu {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  background-size: cover;
  background-position: center;
  background-color: white;
  background-blend-mode: darken;
}
</style>
