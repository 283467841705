<template lang="">
  <div
    class="deck"
    :style="{
      backgroundImage: 'url(' + background + ')',
    }"
    v-bind="$attrs"
  >
    <div class="handle left" @click="rotateReverse"></div>
    <div
      v-for="(d, i) in wines"
      :key="i"
      :class="i == displayId ? 'active' : 'inactive'"
      class="card rounded-lg"
      @click="rotatePause"
    >
      <div class="card-body">
        <div class="card-left image"><img :src="d.img" /></div>
        <div class="card-right">
          <div class="card-header">
            <div class="title">{{ d.title }}</div>
            <div class="brand text-center">
              {{ d.brand_name }} {{ d.wine_type }}
            </div>
          </div>
          <div class="year">Vintage: {{ d.vintage }}</div>
          <div class="color">Variety: {{ d.grape_variety }}</div>
          <div class="taste">Region: {{ d.region }}</div>
          <div class="price">Price: ${{ d.price }}</div>
          <div class="description">{{ d.description }}</div>
        </div>
      </div>
    </div>
    <div class="handle right" @click="rotate"></div>
    <div class="continue" v-show="paused ? true : false">
      <button
        class="bg-success text-center grid text-white"
        @click="setIntervals(interval)"
      >
        Paused - Click to Resume
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    wines: {
      type: Array,
      default: null,
    },
    interval: {
      type: Number,
      default: 10,
    },
    background: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      displayId: 0,
      count: 1,
      slideInterval: 60,
      timer: null,
      paused: 0,
      touchEvent: null,
    };
  },
  created() {},
  mounted() {
    this.slideInterval = this.interval;
    this.setIntervals(this.interval);
  },
  methods: {
    setIntervals(second) {
      this.paused = false;
      this.timer = window.setInterval(() => {
        this.rotate();
      }, 1000 * second);
    },
    rotate() {
      let count = this.wines === null ? 1 : this.wines.length;
      this.displayId = (this.displayId + 1) % count;
    },
    rotateReverse() {
      let count = this.wines === null ? 1 : this.wines.length;
      this.displayId = this.displayId == 0 ? count : this.displayId;
      this.displayId = (this.displayId - 1) % count;
    },
    rotatePause() {
      this.paused = !this.paused;
      if (this.paused) {
        clearInterval(this.timer);
      } else {
        this.setIntervals(this.slideInterval);
      }
    },
  },
};
</script>
<style>
.deck {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  grid-template-columns: 20px 1fr 20px;
  background-size: cover;
  background-position: center;
  background-color: rgba(200, 200, 200, 0.6);
  background-blend-mode: darken;
}
.handle {
  padding: 20px 0px 20px 0px;
  position: absolute;
  top: 0;
  width: 2.5%;
  height: 100%;
}
.handle.left {
  left: 0;
}
.handle.right {
  right: 0;
}
.card {
  padding: 20px 0px 30px 0px;
  background: rgba(255, 255, 255, 0.9);
  width: 95%;
  height: 650px;
  overflow: hidden;
  position: absolute;
  left: 50%;
  top: 10px;
  display: block;
  transform: translate(-50%, 20px);
}
.card:hover {
  cursor: pointer;
}
.card-header {
  display: block;
  margin-bottom: 10px;
}
.card-body {
  font-weight: 500;
  height: 1.2em;
  display: grid;
  grid-template-columns: 400px 1fr;
  gap: 30px;
}
.card .image {
  height: 590px;
}
.card .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card .title,
.card .brand {
  line-height: 1.2em;
}
.card .brand {
  font-size: 2rem;
  font-style: italic;
}
.year,
.color,
.price,
.taste {
  font-size: 1.6rem;
  font-weight: 600;
  color: darkblue;
}
.card .description {
  margin-top: 10px;
  font-size: 1.8em;
  line-height: 1.3em;
  height: 300px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  text-overflow: -o-ellipsis-lastline;
}
.card .title {
  font-size: 3rem;
  font-family: "Sansita Swashed", serif;
  font-weight: 600;
  text-align: center;
}
.continue {
  left: 50%;
  bottom: 10px;
  display: grid;
  transform: translate(-50%, -10px);
  position: absolute;
  max-width: 800px;
  width: 50%;
  opacity: 0.8;
}
.continue button {
  padding: 5px;
  border: none;
  border-radius: 10px;
}
@media (max-width: 1180px) {
  .card .description {
    height: 410px;
  }
}
@media (max-width: 1024px) {
  .card {
    padding: 20px 0px 30px 0px;
    width: 90%;
    height: 440px;
    overflow: hidden;
    position: absolute;
    left: 50%;
    top: 10px;
    display: block;
    transform: translate(-50%, 30px);
  }
  .card-body {
    grid-template-columns: 250px 1fr;
  }
  .card .image {
    max-height: 380px;
  }
  .card .title {
    font-size: 2rem;
    line-height: 1.5em;
  }
  .year,
  .color,
  .price,
  .taste {
    font-size: 1.2rem;
    line-height: 1.2em;
  }
  .card .brand {
    font-size: 1.3rem;
  }

  .card .description {
    font-size: 1.2rem;
    height: 150px;
    -webkit-line-clamp: 6;
  }
  .continue {
    bottom: 5px;
    transform: translate(-50%, -5px);
  }
}
</style>
