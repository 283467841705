<template>
  <div class="container" v-if="wines === null && menus === null">
    <h2 class="title">4K TV</h2>
    <div class="flex">
      <div
        class="item"
        v-for="menu in menupan"
        :key="menu"
        @click="showMenu(menu.id, menu.slide_interval, menu.background)"
      >
        {{ menu.page_name }}
      </div>
      <div class="item"><a href="/video/video.html">Video</a></div>
    </div>
    <h2 class="title">Tablet</h2>
    <div class="flex">
      <div
        class="item"
        v-for="deck in decks"
        :key="deck"
        @click="showSlideshow(deck.id, deck.slide_interval, deck.background)"
      >
        {{ deck.deck_name }}
      </div>
    </div>
  </div>
  <SlideShow
    v-if="wines !== null"
    :wines="wines"
    :interval="interval"
    :background="background"
  />
  <MenuView
    v-if="menus !== null"
    :menus="menus"
    :interval="interval"
    :background="background"
  />
</template>

<script>
import axios from "axios";
import SlideShow from "./components/SlideShow.vue";
import MenuView from "./components/MenuView.vue";

export default {
  name: "App",
  components: { SlideShow, MenuView },
  data() {
    return {
      wines: null,
      decks: null,
      menus: null,
      menupan: null,
      interval: "1",
      touchEvent: null,
    };
  },
  setup() {},
  created() {},
  mounted() {
    axios
      .get("http://api.eatfloweringtree.com/api/deck")
      .then((response) => (this.decks = response.data));
    axios
      .get("http://api.eatfloweringtree.com/api/menupan")
      .then((response) => (this.menupan = response.data));
  },
  unmounted() {},
  methods: {
    showSlideshow(id, interval, background) {
      this.background = background;
      this.interval = interval;
      axios
        .get(
          "http://api.eatfloweringtree.com/api/wine/?WineSearch[deck_id]=" + id
        )
        .then((response) => (this.wines = response.data));
    },
    showMenu(id, interval, background) {
      this.background = background;
      this.interval = interval;
      axios
        .get(
          "http://api.eatfloweringtree.com/api/menu/?MenuSearch[menupan_id]=" +
            id
        )
        .then((response) => (this.menus = response.data));
    },
  },
};
</script>

<style>
body {
  margin: 0;
  background-color: #333333;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.container {
  display: block;
}
h2.title {
  text-align: center;
  padding: 20px;
  margin-top: 20px;
  color: #ffffff;
  font-size: 3rem;
  font-family: "Oregano", serif;
}
.active {
  visibility: visible;
  opacity: 1;
  animation: fadeIn ease 2s;
  -webkit-animation: fadeIn ease 2s;
  -moz-animation: fadeIn ease 2s;
  -o-animation: fadeIn ease 2s;
  -ms-animation: fadeIn ease 2s;
}
.inactive {
  visibility: visible;
  opacity: 0;
  animation: fadeOut ease 2s;
  -webkit-animation: fadeOut ease 2s;
  -moz-animation: fadeOut ease 2s;
  -o-animation: fadeOut ease 2s;
  -ms-animation: fadeOut ease 2s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.flex {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: top;
}
.flex .item {
  flex: 1;
  padding: 20px;
  text-align: center;
  min-width: 200px;
  background: #333366;
  border: 2px solid #dddddd;
  color: #ffffff;
  font-weight: bold;
  font-family: "Oregano", serif;
}
.flex .item:hover {
  cursor: pointer;
}
</style>
